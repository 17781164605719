import { CenterFocusStrong } from "@mui/icons-material"
import { iconClasses } from "@mui/material"
import img from "./img/not-found.png"

const NotFound = () => {


    return (
        <div align='center'>
            
            
            <h1 class="titulo" >Not Found</h1>
            <img src={img}/>
    
        </div>
    )
}

export default NotFound